<template>
  <div class="profile-picture">
    <div class="avatar-img" @click="choiceMedia">
      <div class="has-img">
        <img :src="defaultCover" alt="" />
        <div class="change-img">Edit</div>
      </div>
    </div>
    <media-dialog-radio
      v-if="visibleMediaShowCover"
      :title="'Select Image'"
      :button-text="'Upload Picture'"
      :extensions="'jpg,png,jpeg'"
      :visible="visibleMediaCover"
      :material-type="1"
      :w="w"
      :h="h"
      :limt="limt"
      @updateMedia="uploadSuccessCover"
      suffix="image"
      groupTitle="All Pictures"
      :multi_selection="false"
    ></media-dialog-radio>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mediaDialogRadio from '@/components/media-dialog-radio';

export default {
  components: {
    mediaDialogRadio
  },
  computed: {
    ...mapState({
      userInfo(state) {
        return state.userInfo
      },
    })
  },
  data() {
    return {
      defaultCover: '',
      w: 96,
      h: 96,
      limt: '20MB',
      visibleMediaCover: false, // 封面图处理
      visibleMediaShowCover: false, // 封面图处理
    }
  },
  created() {
    this.defaultCover = this.userInfo.avatar || require('@/assets/images/user-avatar.png')
  },
  methods: {
    async uploadSuccessCover (data, resultList) {
      if (data) {
        const url = resultList[0].url;
        this.defaultCover = url;
        const res = await this.$serve.user.updataAvatar({
          data: {
            avatar: url,
          },
          hideloading: true,
          hideError: true
        })
        if (res === this.$serve.FAIL) {
          return false
        }
        let userInfo = this.userInfo;
        userInfo.avatar = url
        this.$store.commit('userInfoUpdate', {
          ...userInfo
        })
      }
      this.visibleMediaCover = false;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaShowCover = false;
      }, 200);
    },
    choiceMedia() {
      this.visibleMediaShowCover = true;
      let timer = setTimeout(() => {
        clearTimeout(timer);
        timer = null;
        this.visibleMediaCover = true;
      }, 200);
    }
  },
}
</script>

<style lang="scss" scoped>
.profile-picture {
  .has-img {
    width: 48px;
    height: 48px;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
    }
    &:hover {
      .change-img {
        display: block;
      }
    }
    .change-img {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      line-height: 48px;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.4);
      font-family: HelveticaNeue;
      cursor: pointer;
    }
  }
  .avatar-img {
    width: 48px;
  }
}
</style>
