<template>
  <div class="page-subscribe">
    <div class="text">Choose when you’d like to receive emails from us.</div>
    <y-pro-form
      style="width: 100%"
      label-width="280"
      :label-position="'left'"
      :model="model"
      :fields="fields"
      :submitter="false"
    ></y-pro-form>
    <y-popover
      content='Check this box and you will be notified by email 15 minutes before the live event starts.'
      placement='right'
      ref='popover'
      width='390'
      trigger='hover'>
    </y-popover>
    <div class='event-piver'>
      <i class="ui-icon-popover" v-popover:popover  ></i>
    </div>
  </div>
</template>

<script>
  import LiveEvent from './live-event'
  import Storage from './storage'
  import Flow from './flow'

export default {
  data() {
    return {
      model: {

      },
      fields: [
        {
          formItemProps: {
            label: 'Live Event:',
          },
          render: () => {
            return (
              <LiveEvent userInfo={this.userInfo}></LiveEvent>
            )
          }
        },
        /*{
          formItemProps: {
            label: 'Storage:',
          },
          render: () => {
            return (
              <Storage userInfo={this.userInfo}></Storage>
            )
          }
        },
        {
          formItemProps: {
            label: 'Flow:',
          },
          render: () => {
            return (
              <Flow userInfo={this.userInfo}></Flow>
            )
          }
        },*/
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.page-subscribe {
  position: relative;
  ::v-deep .el-form-item__error {
    width: 240px;
    display: flex;
    white-space: nowrap;
  }
  ::v-deep .el-form-item {
    margin-bottom: 20px;
    .el-form-item__label {
      height: 41px;
      line-height: 41px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      padding-bottom: 0;
      text-align: right;
      padding-right: 24px;
    }
    .el-form-item__content {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
      .el-checkbox__label {
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.8);
      }
    }
    label {
      width: 280px;
    }
  }
  .text {
    height: 16px;
    font-size: 13px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.4);
    line-height: 16px;
    margin: 8px 0 18px 16px;
  }
  .event-piver {
    position: absolute;
    top: 47px;
    left: 506px;
  }
}
</style>
