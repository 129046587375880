<template>
  <div class="page-account">
    <div class="tab-component" v-for="(item, index) in tabs" :key="index">
      <div class="tab-title">{{ item.label }}</div>
      <div class="tab-content">
        <component :is="item.activeComponent"></component>
      </div>
    </div>
  </div>
</template>

<script>
import General from './general'
import Password from './password'
import Subscribe from './subscribe'

export default {
  components: {
    General,
    Password,
    Subscribe
  },
  data() {
    return {
      tabs: [
        {
          key: 'general',
          label: 'General',
          activeComponent: 'general',
        },
        {
          key: 'password',
          label: 'Password',
          activeComponent: 'password',
        },
        {
          key: 'subscribe',
          label: 'Subscribe',
          activeComponent: 'subscribe',
        }
      ],
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-tabs__nav {
    margin: 0 16px;
  }
  .el-tabs__item {
    padding: 0 25px !important;
    line-height: 50px;
    height: 50px;
  }
}

.page-account {
  min-height: calc(100vh - 104px);
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  ::v-deep .el-tabs__nav-wrap {
    &::after {
      height: 1px;
      background: rgba(0, 0, 0, 0.06);
    }
  }
  .tab-component {
    .tab-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background: #F7F8FA;
      border-radius: 4px;
      font-weight: 600;
      &::before {
        content: '';
        width: 4px;
        height: 20px;
        background: #FF0043;
        border-radius: 2px;
        display: inline-block;
        vertical-align: sub;
        margin-right: 12px;
      }
    }
  }
}
</style>
