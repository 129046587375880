<template>
  <div class="page-password">
    <div class="password-tips">Reset Your Password</div>
    <y-pro-form
      style="width: 100%"
      label-width="280px"
      :label-position="'left'"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitText: 'Reset Password',
        submitButtonProps: {
          size: 'small'
        },
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      @submit="submit"
    ></y-pro-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { localStore } from '@/utils/store'
import { numAndWordReg } from '@utils/regex'
export default {
  computed: {
    ...mapState({
      screenxs(state) {
        return state.screenData['screen-xs']
      }
    })
  },
  data() {
    return {
      model: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: ''
      },
      fields: [
        {
          type: 'password',
          formItemProps: {
            label: 'Please enter current password' + ':',
            prop: 'currentPassword'
          },
          attributes: {
            placeholder: 'Current Password',
            size: 'medium',
            style: 'width: 320px'
          }
        },
        {
          type: 'password',
          formItemProps: {
            label: 'New Password:',
            prop: 'newPassword'
          },
          attributes: {
            placeholder: 'New Password',
            size: 'medium',
            style: 'width: 320px'
          }
        },
        {
          type: 'password',
          formItemProps: {
            label: 'Comfirm Password:',
            prop: 'confirmPassword'
          },
          attributes: {
            placeholder: 'Comfirm Password',
            size: 'medium',
            style: 'width: 320px'
          }
        }
      ],
      // 表单校验规则配置
      rules: {
        currentPassword: [
          {
            required: true,
            message: 'Password is required',
            trigger: 'change'
          },
          {
            validator: async (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Password is required'))
                return
              }
              if (value.length < 6) {
                callback(new Error('Incorerct password'))
                return
              }
              if (value.length > 20) {
                callback(new Error('Incorerct password'))
                return
              }
              if (!numAndWordReg.test(value)) {
                callback(new Error('Incorerct password'))
                return
              }
              const res = await this.verifyPassword(value)
              if (res !== true) {
                callback(new Error('Incorerct password'))
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        newPassword: [
          {
            required: true,
            message: 'Password is required',
            trigger: 'change'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Password is required'))
                return
              }
              if (value.length < 6) {
                callback(new Error('The password should has at least 6 characters'))
                return
              }
              if (value.length > 20) {
                callback(new Error('Password cannot exceed 20 characters long'))
                return
              }
              if (!numAndWordReg.test(value)) {
                callback(new Error('The password should contain at least a letter and a number'))
                return
              }
              callback()
            },

            trigger: 'blur'
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: 'Password is required',
            trigger: 'change'
          },
          {
            validator: (_, value, callback) => {
              if (!value.trim()) {
                callback(new Error('Password is required'))
                return
              }
              if (value.length < 6) {
                callback(new Error('The password should has at least 6 characters'))
                return
              }
              if (value.length > 20) {
                callback(new Error('Password cannot exceed 20 characters long'))
                return
              }
              if (!numAndWordReg.test(value)) {
                callback(new Error('The password should contain at least a letter and a number'))
                return
              }
              if (this.model.newPassword && value !== this.model.newPassword) {
                callback(new Error('Those passwords didn\'t match. Try again.'))
                return
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    async verifyPassword() {
      const res = await this.$serve.user.verifyPassword({
        data: {
          currentPassword: this.model.currentPassword
        },
        hideloading: true,
        hideError: true
      })
      if (res === this.$serve.FAIL) {
        return this.$store.state.exception['/overseas/user/verifyPassword'].msg
      }
      return true
    },
    async submit() {
      const res = await this.$serve.user.updatePassword({
        data: {
          currentPassword: this.model.currentPassword,
          newPassword: this.model.newPassword
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message({
        type: 'success',
        dangerouslyUseHTMLString: true,
        message: 'Your password has been changed successfully\nplease log in again',
        duration: 2000,
        onClose: () => {
          this.$store.commit('clearState')
          const locale = localStore.get('locale')
          localStore.clearAll()
          if (locale) {
            localStore.set('locale', locale)
          }
          this.$router.push('/user')
        }
      })
    },
    forgotPassword() {
      this.$confirm(
        'Do you want to log out and reset your password?',
        'Prompt',
        {
          confirmButtonText: 'Done',
          cancelButtonText: 'Confirm',
          type: 'warning'
        }
      ).then(() => {
        this.$store.dispatch('logout', {
          forgot: true
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-password {
  position: relative;
  padding-bottom: 28px;
  ::v-deep {
    .el-form-item__error {
      width: 320px;
      display: flex;
      white-space: nowrap;
      position: relative;
    }
    .el-form-item {
      margin-bottom: 20px;
      .el-form-item__label {
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.8);
        padding-bottom: 0;
        text-align: right;
        padding-right: 24px;
      }
      .el-form-item__content {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.8);
        line-height: 32px;
      }
      &.submitter {
        margin-top: 22px;
      }
    }
  }
  .password-tips {
    height: 16px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    line-height: 16px;
    margin: 20px 0 12px 20px;
  }
}
.screen-xs {
  .page-password {
    ::v-deep .el-form-item__error {
      justify-content: flex-start;
    }
  }
}
</style>
