<template>
  <div class="_countries-regions">
    <div class="select-com" v-if="isEdit">
      <y-select
        :options="options"
        placeholder="Select"
        size="medium"
        filterable
        v-model="value"
        style="width: 320px"
        @change="change"
      ></y-select>
      <y-button
        type="text"
        class="_countries-regions__action-cancel"
        @click="cancel"
      >
        Cancel
      </y-button>
    </div>
    <div v-else>
      {{ (userInfo && userInfo.countriesRegions) || '-' }}
      <y-button
        type="text"
        class="_countries-regions__action-edit"
        @click="edit"
      >
        Edit
      </y-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isEdit: false,
      options: [],
      value: ''
    }
  },
  methods: {
    edit() {
      this.isEdit = true
      if (!this.options.length) {
        this.fetchCountriesRegions()
      }
    },
    cancel() {
      this.isEdit = false
    },
    async fetchCountriesRegions() {
      const res = await this.$serve.user.getCountriesAndRegions()
      if (res === this.$serve.FAIL) {
        return
      }
      this.options = res.map((item) => ({
        label: item.name,
        value: item.id
      }))
    },
    change(val) {
      this.updateCountriesOrRegions(val)
    },
    async updateCountriesOrRegions(countriesRegionsId) {
      const res = await this.$serve.user.updateCountriesOrRegions({
        data: {
          countriesRegionsId
        }
      })
      if (res === this.$serve.FAIL) {
        return
      }
      this.$message.success('success')
      this.isEdit = false
      this.updateUserInfo(res.countriesRegionsName)
    },
    updateUserInfo(countriesRegions) {
      this.$store.commit('userInfoUpdate', {
        ...this.userInfo,
        countriesRegions
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.select-com {
  /*margin-bottom: 12px;*/
}
._countries-regions__action-cancel {
  margin-left: 10px;
}
</style>
