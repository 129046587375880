var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-password"},[_c('div',{staticClass:"password-tips"},[_vm._v("Reset Your Password")]),_c('y-pro-form',{staticStyle:{"width":"100%"},attrs:{"label-width":"280px","label-position":'left',"model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"submitter":{
      submitText: 'Reset Password',
      submitButtonProps: {
        size: 'small'
      },
      resetButtonProps: {
        style: {
          display: 'none'
        }
      }
    }},on:{"submit":_vm.submit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }