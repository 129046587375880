<template>
  <div class="page-flow">
    <y-checkbox v-model="insufficientFlow" @change="flowChange">
      Insufficient flow
    </y-checkbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      insufficientFlow: false,
    }
  },
  methods: {
    flowChange (val) {
      console.log(val)
    },
  }
}
</script>

<style lang="scss" scoped>
.page-flow {}
</style>
