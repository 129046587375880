<template>
  <div class="page-live-event">
    <y-checkbox v-model="isNotice" @change="noticeChange">
      Notice before live event
    </y-checkbox>
    <!--    <y-checkbox v-model="shared" @change="sharedChange">-->
    <!--      Someone shared my event-->
    <!--    </y-checkbox>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isNotice: false,
      shared: false
    }
  },
  created() {
    this.isNotice =
      localStorage.getItem('YOLOLIV_LIVE_NOTICE') == 1 ? true : false
    this.getPreferences()
  },
  methods: {
    async getPreferences() {
      const res = await this.$serve.user.getPreferences({
        hideloading: true,
        hideError: true
      })
      if (res === this.$serve.FAIL) {
        return false
      }
      if (!res) return
      this.isNotice = res.liveReminder === 0 ? false : true
    },
    async noticeChange(val) {
      const res = await this.$serve.user.liveReminder({
        data: {
          liveReminder: val ? 1 : 0
        },
        hideloading: true,
        hideError: true
      })
      if (res === this.$serve.FAIL) {
        this.isNotice = !this.isNotice
        return false
      }
      localStorage.setItem('YOLOLIV_LIVE_NOTICE', val ? 1 : 0)
    },
    sharedChange(val) {
      console.log(val)
    }
  }
}
</script>

<style lang="scss" scoped>
.page-live-event {
  ::v-deep {
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
    }
    .el-checkbox__inner::after {
      left: 5px;
      top: 2px;
    }
  }
}
</style>
