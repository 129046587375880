<template>
  <div class="page-storage">
    <y-checkbox v-model="insufficientStorage" @change="spaceChange">
      Insufficient storage space
    </y-checkbox>
    <y-checkbox v-model="storageExpiration" @change="expirationChange">
      Storage expiration
    </y-checkbox>
  </div>
</template>

<script>
export default {
  data() {
    return {
      insufficientStorage: false,
      storageExpiration: false
    }
  },
  methods:{
    spaceChange (val) {
      console.log(val)
    },
    expirationChange (val) {
      console.log(val)
    },
  }
}
</script>

<style lang="scss" scoped>
.page-storage {}
</style>
