<template>
  <div class="page-general">
    <y-pro-form
      style="width: 100%"
      label-width="280px"
      label-position="left"
      :model="model"
      :fields="fields"
      :submitter="false"
    ></y-pro-form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CountriesRegions from './_countries-regions'
import ProfilePicture from './_profile_picture'

export default {
  computed: {
    ...mapState({
      userInfo(state) {
        return state.userInfo
      },
      screenxs(state) {
        return state.screenData['screen-xs']
      }
    })
  },
  data() {
    return {
      model: {
        countriesRegionsId: ''
      },
      fields: [
        {
          formItemProps: {
            label: 'Profile Picture:',
            prop: 'profilePicture',
            class: 'profile-picture',
          },
          render: () => {
            return (
              <ProfilePicture></ProfilePicture>
            )
          }
        },
        {
          formItemProps: {
            label: 'Email:'
          },
          render: () => {
            return this.userInfo?.email || 'Loading'
          }
        },
        {
          formItemProps: {
            label: 'Countries and Regions:',
            prop: 'countriesRegionsId'
          },
          render: () => {
            return (
              <CountriesRegions userInfo={this.userInfo}></CountriesRegions>
            )
          }
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.page-general {
  padding: 20px 0;
  ::v-deep .el-form-item {
    margin-bottom: 19px;
    .el-form-item__label {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.8);
      padding-bottom: 0;
      text-align: right;
      padding-right: 24px;
    }
    .el-form-item__content {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.8);
      font-weight: 600;
    }
    &.profile-picture {
      .el-form-item__label {
        margin-top: -3px;
      }
    }
  }
}
</style>
